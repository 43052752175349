import "./styles.css";
import { useEffect } from "react";
import { useParams } from "react-router";
import * as VIEW from "viewer-lib";

function App() {
    const { id } = useParams();

    useEffect(() => {
        const query = Object.fromEntries(new URLSearchParams(window.location.search));

        const options = {
            autoload: query?.autoload ? query.autoload === 'true' : undefined,
            viewType: query?.viewType ? query.viewType : undefined,
            language: query?.language ? query.language : undefined,
            backgroundColor: query?.backgroundColor ? query.backgroundColor : undefined,
            showEmbedIcon: query?.showEmbedIcon  === 'false' ? query.showEmbedIcon = false : query.showEmbedIcon = true
        }

        const targetEl = document.getElementById('root');
        if (!targetEl) {
            throw new Error("Root element is not defined");
        }

        const viewer = new VIEW.Viewer();
        viewer.initialize(targetEl, id, options);

        const receiveMessage = async (event) => {
            const data = event.data;
            if (!data) return;

            if (data.type === "language") {
                viewer?.changeLanguage(data.value);
            }
        }

        window.addEventListener("message", receiveMessage, false);
        return () => {
            window.removeEventListener("message", receiveMessage);
        };

    }, [id]);

    return (<></>);
}

export default App;
