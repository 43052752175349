import React from 'react'
import { useLocation } from 'react-router';

import styles from "../Styles/error.module.css";

const E404 = () => {
    let locaiton = useLocation();
    return error(code.E404, "The page you are looking for does not exist", locaiton);
}

function error(code, message, location) {
    return (
        <div className={ styles.errorContainer }>
            <h5>{ code+' - '+ message+': '} <code>{ location.pathname }</code></h5>
        </div>
    );
}

// type = {code: a, messabe: b}
const code = {
    E404: "404"
}

export const Errors = { E404 };